import React, { FC, useEffect, useState } from "react";
import Api from "../../../../../services/api";
import NFTSelection from "./nftSelection";
import LoadingContainer from "../../../../../components/loading/loadingContainer";
import { NFTExport } from "models/exports";

interface UnqualifiedNFTsProps {
  unqualifiedNFTs: {
    order: string;
    tokenIds: string[];
  }[];
  collection: string;
}

const UnqualifiedNFTs: FC<UnqualifiedNFTsProps> = ({
  unqualifiedNFTs,
  collection,
}) => {
  const [nfts, setNfts] = useState<NFTExport[]>([]);
  const [orders, setOrders] = useState<string[]>([]);

  useEffect(() => {
    if (unqualifiedNFTs.length > 0) {
      const api = new Api();

      let orderIds = [];
      let tokenIds = [];
      unqualifiedNFTs.forEach((nftWithOrder) => {
        orderIds.push(
          Array(nftWithOrder.tokenIds.length).fill(nftWithOrder.order)
        );
        tokenIds = [...tokenIds, nftWithOrder.tokenIds];
      });
      setOrders(orderIds);

      for (let i = 0; i < orderIds.length; i = i + 50) {
        api
          .getNFTsForContract(tokenIds.slice(i, i + 50), collection)
          .then((response) => {
            setNfts(nfts.concat(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [unqualifiedNFTs]);

  return (
    <div className="flex flex-row flex-wrap gap-x-18 gap-y-16">
      {nfts.length > 0
        ? nfts.map((nft, index) => {
            return (
              <NFTSelection
                nft={nft}
                order={orders[index]}
                disabled={true}
                showExpiration={true}
                key={`${nft.id}-${orders[index]}`}
              />
            );
          })
        : orders.map((_, index) => (
            <div
              key={`loading-unqualified-${index}`}
              className="flex flex-col"
              style={{ width: "136px", minHeight: "172px" }}
            >
              <LoadingContainer
                variant="rectangular"
                height={124}
                sx={{ width: "100%" }}
              />
              <div className="flex flex-col mt-8">
                <LoadingContainer variant="text" height={20} width={76} />
                <LoadingContainer variant="text" height={20} width={76} />
              </div>
            </div>
          ))}
    </div>
  );
};

export default UnqualifiedNFTs;
