import { NFTExport } from "models/exports";
import React, { FC, useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import InventoryCard from "./inventoryCard";

interface NFTScrollContainerProps {
  inventoryNFTs: NFTExport[];
  title?: string;
  subtitle?: string;
  onNftRemoved?: (nft: NFTExport) => void;
  onClearAll?: () => void;
}

const NFTScrollContainer: FC<NFTScrollContainerProps> = ({
  inventoryNFTs,
  title = "",
  subtitle = "",
  onNftRemoved = () => {},
  onClearAll = () => {},
}) => {
  const handleRemoveNft = (nft: NFTExport) => {
    onNftRemoved(nft);
  };

  const [nfts, setNfts] = useState<NFTExport[] | []>(
    new Array(50).fill(undefined)
  );

  //Every time the selected nft list updates
  //Rerender the list to display
  useEffect(() => {
    // if less than 50 items, we'll add undefined so we show blank slots on the inventory picker
    let resizedInventoryNFTs = new Array(50)
      .fill(undefined)
      .slice(inventoryNFTs.length);
    let addedInventoryNFTs = inventoryNFTs.concat(resizedInventoryNFTs);

    setNfts(addedInventoryNFTs);
  }, [inventoryNFTs]);

  return (
    <div className="bg-black pb-16 rounded-t-sm pl-16 pt-16 border border-dark-divider">
      <div className="flex flex-row flex-start items-center mr-16">
        <h2 className="text-dark-light-grey">{title}</h2>
        <p className="body1 text-dark-white-64 ml-32">{subtitle}</p>
        <button
          type="button"
          disabled={nfts.length === 0}
          className={
            (nfts.length === 0 ? "text-opacity-50 " : "") +
            "btn-second text-dark-red content-center focus:ring-transparent focus:outline-none ml-auto"
          }
          onClick={onClearAll}
        >
          Clear all
        </button>
      </div>
      <ScrollContainer className="flex-grow grid grid-rows-1 grid-flow-col overflow-hidden overflow-x-auto no-scrollbar gap-12 justify-start scroll-container">
        {nfts.map((nft, index) => (
          <InventoryCard
            nft={nft}
            onRemoveNft={() => handleRemoveNft(nft)}
            key={
              nft
                ? `${nft.type.contract}-${nft.id}`
                : `${index}-${Date.now().toString().slice(-4)}`
            }
          />
        ))}
      </ScrollContainer>
    </div>
  );
};

export default NFTScrollContainer;
