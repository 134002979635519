import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatUnits } from "ethers/lib/utils";
import { AppState } from "state";
import Card from "components/common/card/card";
import NFTSelection from "../swapNft/nftSelection";

interface ConfirmProps {}

const Confirm: FC<ConfirmProps> = ({}) => {
  const collections = useSelector<AppState, AppState["bounty"]["collections"]>(
    (state) => state.bounty.collections
  );
  const selected = useSelector<AppState, AppState["bounty"]["selected"]>(
    (state) => state.bounty.selected
  );
  const albumToken = useSelector<AppState, AppState["bounty"]["albumToken"]>(
    (state) => state.bounty.albumToken
  );

  const [totalReward, setTotalReward] = useState("");
  useEffect(() => {
    if (selected && collections) {
      let totalRewards = 0;
      selected.forEach((order) => {
        totalRewards +=
          collections[order.type.contract][order.orderId].redemptionRate;
      });

      setTotalReward(
        parseInt(
          formatUnits(
            totalRewards.toLocaleString("fullwide", { useGrouping: false })
          )
        ).toFixed(0)
      );
    }
  }, [selected, collections]);

  return (
    <div className="flex flex-col h-full pb-16">
      <div
        className="flex flex-col-reverse md:flex-row gap-24 w-full mx-auto mt-48 overflow-y-auto"
        style={{ maxWidth: "1142px" }}
      >
        <Card
          style={{ width: "100%" }}
          title="Swapping"
          subtitle={`${selected.length} NFT${selected.length > 1 ? "s" : ""}`}
        >
          <div className="flex flex-row flex-wrap gap-x-32 flex-y-24 overflow-y-auto">
            {selected.map((selection) => (
              <NFTSelection
                key={`${selection.orderId}-${selection.id}-confirm`}
                nft={selection}
                order={selection.orderId}
                disabled={true}
              />
            ))}
          </div>
        </Card>
        <Card style={{ width: "100%", height: "132px" }} title="Receiving">
          <p className="body1">
            {totalReward} ${albumToken}
          </p>
        </Card>
      </div>
    </div>
  );
};

export default Confirm;
