import { navigate, useLocation } from "@reach/router";
import Button from "components/common/button/button";
import { FullAlbumDetails } from "models/exports";
import React, { FC, useEffect, useState } from "react";
import queryString from "query-string";
import Loading from "components/loading/loading";
import BountyContainer from "./bountyContainer";
import {
  clearSelectedNFTs,
  initializeAlbumDetails,
} from "state/bounty/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import Modal from "components/common/modal/modal";
import ModalSubtitle from "components/common/modal/modalSubtitle";
import ModalTitle from "components/common/modal/modalTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useBatchFill } from "hooks/proposals/bounty/useBatchFill";
import { OrderSelection, OrderGrouping } from "models/bounty";
import { useGetContractMetadata } from "hooks/nfts/useGetContractMetadata";
import { useGetBountiesForAlbum } from "hooks/proposals/bounty/useGetBountiesForAlbum";
import { useSafeTransferFrom } from "hooks/proposals/bounty/useSafeTransferFrom";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";

interface BountyBoardProps {
  album: FullAlbumDetails;
}

const BountyBoard: FC<BountyBoardProps> = ({ album }) => {
  const dispatch = useDispatch();
  useGetContractMetadata(album.id);

  const { account } = useEthers();

  const location = useLocation();
  const [boardState, setBoardState] = useState<BountyBoardState>(
    BountyBoardState.INIT
  );

  const [bountiesLoading, bountiesError] = useGetBountiesForAlbum(album.id);
  const selected = useSelector<AppState, AppState["bounty"]["selected"]>(
    (state) => state.bounty.selected
  );

  //Confirmation state stuff
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [pendingTxn, setPendingTxn] = useState(false);
  const [batchFillCall, batchFillState] = useBatchFill();
  const [safeTransferFrom, safeTransferFromState] = useSafeTransferFrom(
    selected.length > 0
      ? selected[0].type.contract
      : ethers.constants.AddressZero
  );
  const collections = useSelector<AppState, AppState["bounty"]["collections"]>(
    (state) => state.bounty.collections
  );
  const handleSendOrders = () => {
    setPendingTxn(true);
    if (selected.length === 1) {
      safeTransferFrom(
        account,
        selected[0].id,
        getOrdersFromSelected(selected)[0].order
      );
    } else {
      batchFillCall(getOrdersFromSelected(selected));
    }
  };
  useEffect(() => {
    if (
      batchFillState.status !== "Mining" &&
      safeTransferFromState.status !== "Mining"
    ) {
      setPendingTxn(false);
    } else if (
      batchFillState.status === "Mining" ||
      safeTransferFromState.status === "Mining"
    ) {
      //Clear out state and navigate to album page on submit
      dispatch(clearSelectedNFTs());
      navigate(`/album/${album.name}`);
    }
  }, [batchFillState, safeTransferFromState]);

  /**
   * Builds out our order in a format for contract call
   * @param selected the selected orders/nfts
   * @returns
   */
  const getOrdersFromSelected = (
    selected: OrderSelection[]
  ): OrderGrouping[] => {
    //Make a map so we can aggregate collections->tokenIds[] grouping that belong to the same order
    let orderMap: {
      [order: string]: OrderGrouping;
    } = {};

    selected.forEach((selection) => {
      //If the order exists
      if (orderMap[selection.orderId]) {
        //Get index of the releavant collection
        const index = orderMap[selection.orderId].erc721Groupings.findIndex(
          (grouping) => {
            return grouping.erc721 === selection.type.contract;
          }
        );
        //If collection not exist, push a new collection->tokenId[] grouping
        if (index != -1) {
          orderMap[selection.orderId].erc721Groupings[index].ids.push(
            selection.id
          );
        }
        //If collection exists push the tokenId to the grouping
        else {
          orderMap[selection.orderId].erc721Groupings.push({
            erc721: selection.type.contract,
            ids: [selection.id],
          });
        }
      } else {
        //If doesn't exist, initialize the ordera
        const order = collections[selection.type.contract][selection.orderId];
        orderMap[selection.orderId] = {
          order: {
            //Order params
            validator: order.validator,
            erc20: order.tokenID,
            nftBeneficiary: order.nftBeneficiary,
            tokensPerTribute: order.redemptionRate.toLocaleString("fullwide", {
              useGrouping: false,
            }), //Convert to string to prevent overflows
            expirationTime: order.expirationDate / 1000, //Convert to milliseconds
          },
          erc721Groupings: [
            {
              //Push the new collection->tokenId[] grouping
              erc721: selection.type.contract,
              ids: [selection.id],
            },
          ],
        };
      }
    });

    //Make a OrderGrouping[] to pass to contract call
    let orders = [];
    Object.keys(orderMap).forEach((order) => {
      orders.push(orderMap[order]);
    });

    return orders;
  };
  //End Confirmation state stuff

  useEffect(() => {
    //Get the state on load
    const query = location?.search;
    const { step } = queryString.parse(query);
    switch (step) {
      case "approval":
        setBoardState(BountyBoardState.APPROVE);
        break;
      case "confirm":
        setBoardState(BountyBoardState.CONFIRM);
        break;
      case "complete":
        setBoardState(BountyBoardState.COMPLETE);
        break;
      default:
        setBoardState(BountyBoardState.SWAP);
        break;
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(
      initializeAlbumDetails({
        albumName: album.name,
        albumToken: album.tokenName,
      })
    );
  }, []);

  const handleContinue = () => {
    if (boardState === BountyBoardState.SWAP && selected.length === 1) {
      //if we only have one nft selected, bypass the approve state
      navigate(
        `?step=${BountyBoardStateMetadata[BountyBoardState.CONFIRM].path}`
      );
    } else if (boardState < BountyBoardState.CONFIRM) {
      navigate(`?step=${BountyBoardStateMetadata[boardState + 1].path}`);
    } else if (boardState === BountyBoardState.CONFIRM) {
      setIsShowConfirm(true);
    }
  };

  const allContractsApproved = useSelector<
    AppState,
    AppState["bounty"]["allContractsApproved"]
  >((state) => state.bounty.allContractsApproved);

  const canContinue = (): boolean => {
    switch (boardState) {
      case BountyBoardState.SWAP:
        if (selected.length === 0) {
          return false;
        } else {
          return true;
        }
      case BountyBoardState.APPROVE:
        if (allContractsApproved) {
          return true;
        } else {
          return false;
        }
      case BountyBoardState.CONFIRM:
        //No restrictions on confirm page
        return true;
      case BountyBoardState.COMPLETE:
        return false;
        break;
      default:
        return false;
    }
  };

  return (
    <div className="flex flex-col-reverse md:flex-col flex-grow h-full px-32">
      <PageHeader title={BountyBoardStateMetadata[boardState].title}>
        <div className="flex flex-row md:space-x-32 md:items-end w-full">
          <a
            className="hidden md:block focus-within:body2 btn-second text-dark-white-64 hover:text-white"
            href="https://docs.szns.io/tutorials/bounty-board#bounty-board-submit-nfts"
            target="_blank"
          >
            Learn about swapping NFTs
          </a>
          <div className="w-full md:w-208">
            <Button
              disabled={!canContinue()}
              width="full"
              onClick={handleContinue}
            >
              {boardState === BountyBoardState.CONFIRM ? "Submit" : "Continue"}
            </Button>
          </div>
        </div>
      </PageHeader>
      <div className="flex flex-col flex-grow">
        {bountiesLoading ? (
          <div className="flex flex-col flex-grow justify-center items-center">
            <Loading />
          </div>
        ) : (
          <BountyContainer state={boardState} />
        )}
      </div>
      <Modal isOpen={isShowConfirm} onClose={() => setIsShowConfirm(false)}>
        <ModalTitle>
          <h2>Do you wish to proceed?</h2>
          <button
            className="focus:outline-none"
            onClick={() => setIsShowConfirm(false)}
          >
            <CloseIcon className="fill-current text-white" />
          </button>
        </ModalTitle>
        <div className="flex flex-col space-y-24 mt-16">
          <ModalSubtitle>
            <p className="body1 text-white whitespace-pre-line">{`This action will move NFTs from your wallet to the specified Album in return for  album tokens.\n\nYou will no longer have access to the NFTs once submitted.`}</p>
          </ModalSubtitle>
          <div className="flex flex-col space-y-16">
            <Button
              width="full"
              onClick={handleSendOrders}
              disabled={pendingTxn}
            >
              {pendingTxn ? <Loading size={24} color="#000000" /> : "Continue"}
            </Button>
            <Button
              width="full"
              fill={false}
              onClick={() => setIsShowConfirm(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BountyBoard;

interface PageHeaderProps {
  title?: string;
}

const PageHeader: FC<PageHeaderProps> = ({ title = "", children }) => (
  <div className="w-full ">
    <div className="flex flex-col md:flex-row justify-between items-end text-white border-b border-dark-divider py-8 md:pt-0 md:pb-32">
      <div className="display1 hidden md:block">{title}</div>
      <div className="w-full md:w-auto flex flex-row justify-center">
        {children}
      </div>
    </div>
  </div>
);

export enum BountyBoardState {
  INIT = 0,
  SWAP = 1,
  APPROVE = 2,
  CONFIRM = 3,
  COMPLETE = 4,
}

export const BountyBoardStateMetadata = {
  [BountyBoardState.INIT]: {
    title: "",
    path: "",
  },
  [BountyBoardState.SWAP]: {
    title: "Swap NFTs",
    path: "",
  },
  [BountyBoardState.APPROVE]: {
    title: "Approve Contracts",
    path: "approval",
  },
  [BountyBoardState.CONFIRM]: {
    title: "Final Confirmation",
    path: "confirm",
  },
  [BountyBoardState.COMPLETE]: {
    title: "Complete",
    path: "complete",
  },
};
