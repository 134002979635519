//This handles all routes to /album/*
import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Layout from "../../components/layout";
import PrivateRoute from "../../components/app/privateRoute";
import AlbumDetail from "../../components/album/albumDetail/albumDetail";
import Proposal from "../../components/album/proposal/proposal";
import Loading from "../../components/loading/loading";
import { useGetAlbumDetails } from "../../hooks/album/useGetAlbumDetails";
import SEO from "../../components/seo";
import BountyBoard from "../../components/album/bounty";
import { useDispatch } from "react-redux";
import { resetBounties } from "../../state/bounty/actions";

const AlbumPage = ({ albumName }) => {
  //Load the album first and then push it down to the appropriate router
  const [details, loading, error] = useGetAlbumDetails(albumName);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (!loading) {
        dispatch(resetBounties());
      }
    };
  }, []);

  if (loading) {
    return (
      <Layout>
        <SEO title="Album" description="Check out this NFT Album" />
        <div className="flex text-white">
          <div className="mx-auto">
            <Loading />
          </div>
        </div>
      </Layout>
    );
  } else if (error) {
    return (
      <Layout>
        <SEO title="Album" />
        <div className="flex flex-col w-full h-full justify-center">
          <h1 className="text-white text-center">
            {error.replace("fund", "album")}
          </h1>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Router className="flex flex-col flex-grow" basepath="/album/:albumName">
        <AlbumDetail path="/" album={details} />
        <PrivateRoute
          path="/proposal/*"
          component={() => <Proposal album={details} />}
        />
        <PrivateRoute
          path="/bounty_board"
          component={() => <BountyBoard album={details} />}
        />
      </Router>
    </Layout>
  );
};

export default AlbumPage;
