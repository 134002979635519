import { useSelector } from "react-redux";
import { AppState } from "../../../state";

// Hook that retrieves how many bounty orders a user has chosen
export const usePendingOrders = (collection: string, order: string) => {
  const selected = useSelector<AppState, number>((state) => {
    const selectedOrders = [];
    state.bounty.selected.forEach((s) => {
      if (s.orderId === order) selectedOrders.push(s);
    });

    return selectedOrders.length;
  });

  const available =
    useSelector<AppState, number>(
      (state) => state.bounty.collections[collection][order]["available"]
    ) - selected;

  return [selected, available];
};
