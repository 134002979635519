import React, { FC } from "react";
import { BountyBoardState } from ".";
import Approval from "./steps/approval/approval";
import Confirm from "./steps/confirm/confirm";
import SwapNFT from "./steps/swapNft";

interface BountyContainerProps {
  state: BountyBoardState;
}

const BountyContainer: FC<BountyContainerProps> = ({ state }) => {
  switch (state) {
    case BountyBoardState.SWAP:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <SwapNFT />
        </div>
      );
    case BountyBoardState.APPROVE:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <Approval />
        </div>
      );
    case BountyBoardState.CONFIRM:
      return (
        <div
          className="md:mx-32 flex flex-grow flex-col"
          style={{ height: "calc(100vh - 104px - 85px)" }}
        >
          <Confirm />
        </div>
      );
    case BountyBoardState.COMPLETE:
      return null;
    default:
      return null;
  }
};

export default BountyContainer;
