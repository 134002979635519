import { BountyType } from "models/bounty";
import {
  BountyOrderExport,
  BountyValidatorExport,
  NFTExport,
} from "models/exports";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/api";
import { AppState } from "state";

// Check orders and determine which of the wallet's NFTs are qualified
const filterOrdersWithQualifiedNFTs = (
  ordersForCollection: { [orderId: string]: BountyOrderExport },
  walletNFTs,
  validators: { [validatorId: string]: BountyValidatorExport }
) => {
  const qualifiedOrdersToTokenNFTsMap: {
    [orderId: string]: NFTExport[]; //We have the nft data from walletNFTs param
  } = {};

  const unqualifiedOrdersToTokenIdsMap: {
    [orderId: string]: string[]; //This is a string since we don't have the data pulled
  } = {};

  Object.entries(ordersForCollection).map(
    ([_, order]: [string, BountyOrderExport]) => {
      if (order.available > 0) {
        if (validators[order.validator].type === BountyType.Address) {
          //If address validator
          validators[order.validator].algorithm.map((a) => {
            let tokenIds = walletNFTs.filter((nft) => {
              return nft.type.contract === a?.collectionAddress.toLowerCase();
            });
            if (tokenIds.length > 0) {
              qualifiedOrdersToTokenNFTsMap[order.id] = tokenIds;
            }
          });
        } else {
          //If specific TokenId
          validators[order.validator].options.map((option) => {
            option.tokenIDs.find((id) => {
              let nftFound = walletNFTs.find((nft) => {
                return Boolean(
                  nft.type.contract ===
                    option?.collectionAddress.toLowerCase() && nft.id === id
                );
              });

              if (nftFound) {
                (qualifiedOrdersToTokenNFTsMap[order.id] ??= []).push(nftFound);
              } else {
                (unqualifiedOrdersToTokenIdsMap[order.id] ??= []).push(id);
              }
            });
          });
        }
      }
      //TODO: implement AllValidator (eg: BountyType.All) case later but not used now
    }
  );

  const sortedOrders = getSortedOrdersByPrice(ordersForCollection);
  //sort the qualified and unqualified nft lists
  let qualifiedNFTs: {
    order: string;
    nfts: NFTExport[];
  }[] = [];

  let unqualifiedNFTs: {
    order: string;
    tokenIds: string[];
  }[] = [];

  sortedOrders.map((order) => {
    if (qualifiedOrdersToTokenNFTsMap[order.id]) {
      qualifiedNFTs.push({
        order: order.id,
        nfts: qualifiedOrdersToTokenNFTsMap[order.id].sort((a, b) => {
          //Sort the list alphabetically
          if (a.id < b.id) {
            return -1;
          } else if (a.id > b.id) {
            return 1;
          } else {
            0;
          }
        }),
      });
    }
    if (unqualifiedOrdersToTokenIdsMap[order.id]) {
      unqualifiedNFTs.push({
        order: order.id,
        tokenIds: unqualifiedOrdersToTokenIdsMap[order.id].sort((a, b) => {
          //Sort the list alphabetically by token id
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          } else {
            0;
          }
        }),
      });
    }
  });

  return { qualifiedNFTs, unqualifiedNFTs };
};

// Retrieves the Wallet's qualified NFTs for a specific collection contract
export const useGetNFTsForCollection = (
  address: string,
  contract: string
): [
  {
    order: string;
    nfts: NFTExport[];
  }[],
  {
    order: string;
    tokenIds: string[];
  }[],
  boolean,
  any
] => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();
  const [qualified, setQualified] = useState<
    {
      order: string;
      nfts: NFTExport[]; //Since we get the users wallet nfts with metadata we can set the nft info here
    }[]
  >([]);
  const [unqualified, setUnQualified] = useState<
    {
      order: string;
      tokenIds: string[]; //We only have the token ID here so need to pull later for metadata if needed
    }[]
  >([]);
  const collectionsStore = useSelector<
    AppState,
    AppState["bounty"]["collections"]
  >((state) => state.bounty.collections);
  const validators = useSelector<AppState, AppState["bounty"]["validators"]>(
    (state) => state.bounty.validators
  );

  const getNFTsForCollection = (
    cursor: string = "",
    calledNfts: NFTExport[]
  ): Promise<NFTExport[]> => {
    const api = new Api();

    return api
      .getNFTsForCollection(address, contract, cursor)
      .then((response) => {
        const walletNFTs = response.data.assets;
        //If we get a next field, it means we have more to load since api can only handle batches of 50
        if (response.data.next) {
          return getNFTsForCollection(response.data.next, walletNFTs);
        } else {
          return walletNFTs;
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        return null;
      });
  };

  useEffect(() => {
    if (address) {
      getNFTsForCollection("", [])
        .then((response) => {
          const ordersForCollection = collectionsStore[contract.toLowerCase()];
          const { qualifiedNFTs, unqualifiedNFTs } =
            filterOrdersWithQualifiedNFTs(
              ordersForCollection,
              response,
              validators
            );
          setQualified(qualified.concat(qualifiedNFTs));
          setUnQualified(unqualified.concat(unqualifiedNFTs));
        })
        .catch((error) => {
          console.log(error);
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [address, collectionsStore]);

  return [qualified, unqualified, loading, error];
};

//Returns a list of bounty orders descending by redemption limit
const getSortedOrdersByPrice = (ordersForCollections: {
  [orderId: string]: BountyOrderExport;
}): BountyOrderExport[] => {
  //Get the orders
  let orders: BountyOrderExport[] = [];
  Object.entries(ordersForCollections)
    .sort(([_aId, aOrder], [_bId, bOrder]) => {
      return bOrder.redemptionRate - aOrder.redemptionRate;
    })
    .map(([_, order]) => {
      orders.push(ordersForCollections[order.id]);
    });

  return orders;
};
