import { useEthers } from "@usedapp/core";
import { ethers } from "ethers";
import { useGetNFTsForCollection } from "../../../../../hooks/proposals/bounty/useGetNFTsForCollection";
import React, { FC } from "react";
import NFTSelection from "./nftSelection";
import { BountyOrderExport } from "../../../../../models/exports";
import { BountyType } from "../../../../../models/bounty";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../state";
import { formatUnits } from "ethers/lib/utils";
import UnqualifiedNFTs from "./unqualifiedNFTs";
import LazyLoad from "react-lazyload";
import { Collection } from "../../../../../models/collection";
import { usePendingOrders } from "../../../../../hooks/proposals/bounty/usePendingOrders";
import { DateTime } from "luxon";

interface CollectionOrdersProps {
  collection: Collection;
}

const CollectionOrders: FC<CollectionOrdersProps> = ({ collection }) => {
  const { account } = useEthers();
  const [qualifiedNFTs, unqualifiedsNFTs, loading, error] =
    useGetNFTsForCollection(account, collection.address);
  const albumToken = useSelector<AppState, AppState["bounty"]["albumToken"]>(
    (state) => state.bounty.albumToken
  );
  const validators = useSelector<AppState, AppState["bounty"]["validators"]>(
    (state) => state.bounty.validators
  );

  //Get address orders for this collection
  const addressOrderData = useSelector<AppState, BountyOrderExport[]>(
    (state) => {
      //Get orders for this collection
      let addressOrders = Object.entries(
        state.bounty.collections[collection.address]
      )
        .filter(([_, order]: [string, BountyOrderExport]) => {
          return validators[order.validator]?.type === BountyType.Address;
        })
        .map(([_, order]: [string, BountyOrderExport]) => {
          return order; //Just get the order from the object
        })
        .sort((a, b) => {
          //Sort by highest redemption rate
          return b.redemptionRate - a.redemptionRate;
        });
      return addressOrders;
    }
  );

  return (
    <div className="flex flex-col space-y-24">
      <div>
        <h3 className="mb-16 responsive-h3 text-dark-light-grey">
          Your qualifying NFTs
        </h3>
        <div className="flex flex-row flex-wrap gap-x-18 gap-y-16">
          {!loading &&
            qualifiedNFTs.map((qualified) => {
              return qualified.nfts.map((nft, index) => {
                return (
                  <div key={`${nft}-${qualified.order}-${index}`}>
                    <NFTSelection nft={nft} order={qualified.order} />
                  </div>
                );
              });
            })}
        </div>
      </div>
      <div className="flex flex-col">
        <h3 className="mb-16 responsive-h3 text-dark-light-grey">
          Any {collection.name} NFT
        </h3>
        <div className="flex flex-row flex-wrap gap-x-18 gap-y-16">
          {addressOrderData.map((order) => {
            const [selected, available] = usePendingOrders(
              collection.address,
              order.id
            );

            return (
              <div key={order.id} className="p-16 border-2 border-dark-divider">
                <p className="body2 text-white">
                  {parseInt(
                    formatUnits(
                      order.redemptionRate
                        .toLocaleString("fullwide", { useGrouping: false })
                        .toString()
                    )
                  )}{" "}
                  ${albumToken}
                </p>
                <p className="body1 text-dark-white-64">
                  {order.maxFill > Number.MAX_SAFE_INTEGER
                    ? "Unlimited"
                    : available}{" "}
                  of{" "}
                  {order.maxFill > Number.MAX_SAFE_INTEGER
                    ? "Unlimited"
                    : order.maxFill}{" "}
                  redemptions remaining
                </p>
                <p className="body1 text-dark-white-64">
                  Expires{" "}
                  {DateTime.fromMillis(order.expirationDate).toFormat(
                    "dd LLL yyyy"
                  )}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col">
        <h3 className="mb-4 responsive-h3 text-dark-light-grey">
          Specific NFTs
        </h3>
        {/* TODO: make opensea url */}
        {/* <a
                    href="#"
                    className="text-dark-red body2 mb-24">
                    View all on OpenSea
                </a> */}
        <LazyLoad overflow={true} height={172}>
          <UnqualifiedNFTs
            unqualifiedNFTs={unqualifiedsNFTs}
            collection={collection.address}
          />
        </LazyLoad>
      </div>
    </div>
  );
};

export default CollectionOrders;
