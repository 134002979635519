import Button from "components/common/button/button";
import { OrderSelection } from "models/bounty";
import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import Collection from "./collection";
import { Pagination } from "@mui/material";
import Search from "../../../../../components/common/search/search";
import { Collection as CollectionObj } from "models/collection";
import { clearSelectedNFTs, nftDeselected } from "state/bounty/actions";
import NFTScrollContainer from "components/common/nft/nftScrollContainer";

interface SwapNftProps {}

const SwapNFT: FC<SwapNftProps> = ({}) => {
  const dispatch = useDispatch();
  const collections = useSelector<AppState, AppState["bounty"]["collections"]>(
    (state) => state.bounty.collections
  );
  const allMetadata = useSelector<AppState, CollectionObj>(
    (state) => state.bounty.collectionMetadata
  );
  const [alphabetizedData, setAlphabetizedData] = useState([]);
  const selected = useSelector<AppState, AppState["bounty"]["selected"]>(
    (state) => state.bounty.selected
  );
  const [filter, setFilter] = useState("");
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [paginatedCollections, setPaginatedCollections] = useState([]);
  const [page, setPage] = useState(1);
  const PER_PAGE = 20;

  const handleRemoveNft = (nft: OrderSelection) => {
    dispatch(nftDeselected(nft));
  };

  const handleClearAll = () => {
    dispatch(clearSelectedNFTs());
  };

  useEffect(() => {
    if (Object.keys(collections).length > 0) {
      const noMissingMetadata = [];
      Object.keys(collections).forEach((address) => {
        if (allMetadata[address]?.name) {
          noMissingMetadata.push({
            address: address,
            name: allMetadata[address]?.name,
          });
        }
      });

      noMissingMetadata.sort(function (a, b) {
        const nameA = a?.name.toUpperCase();
        const nameB = b?.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setAlphabetizedData(noMissingMetadata);
    }
  }, []);

  // Filters collection data - matches collection data with metadata and filters by collection name
  useEffect(() => {
    if (filter !== "" && alphabetizedData.length > 0) {
      let filteredList = alphabetizedData.filter((collection) => {
        return collection?.name.toUpperCase().includes(filter.toUpperCase());
      });
      setFilteredCollections(filteredList);
      setPage(1);
    } else {
      setFilteredCollections(alphabetizedData);
    }
  }, [filter, alphabetizedData]);

  // Pagination is handled using PER_PAGE constant and page state that is tied to the Pagination component
  useEffect(() => {
    const begin = (page - 1) * PER_PAGE;
    const end = begin + PER_PAGE;
    setPaginatedCollections(filteredCollections.slice(begin, end));
    document.getElementById("bountyCollections").scroll(0, 0);
  }, [filteredCollections, page]);

  const handlePageChange = (event, value) => {
    if (value) {
      setPage(value);
    }
  };

  return (
    <div className="flex flex-col flex-grow h-full relative">
      <div
        className="flex flex-col lg:flex-row w-full text-white my-16 lg:my-32 self-center justify-between"
        style={{ maxWidth: "984px" }}
      >
        <div className="flex flex-row w-full lg:w-1/2 text-white">
          <Search
            value={filter}
            placeholderText="Search for an NFT collection"
            onChange={(e) => setFilter(e.target.value)}
            onClear={() => setFilter("")}
          />
        </div>
        <div className="flex flex-row justify-center lg:justify-end mt-16 lg:mt-0 text-white">
          <Pagination
            sx={{
              ".Mui-selected": {
                backgroundColor: "black !important",
                color: "#FFFFFF",
              },
              ".Mui-selected: hover": {
                backgroundColor: "black",
              },
              ".Mui-focusVisible": {
                backgroundColor: "black",
              },
              ".MuiPaginationItem-ellipsis": {
                color: "white",
              },
              ".MuiPaginationItem-icon": {
                color: "white",
              },
              ".MuiPaginationItem-page": {
                color: "white",
              },
            }}
            count={Math.ceil(filteredCollections.length / PER_PAGE)}
            boundaryCount={1}
            siblingCount={0}
            page={page}
            onChange={handlePageChange}
            hideNextButton={
              Math.ceil(filteredCollections.length / PER_PAGE) <= 1
                ? true
                : false
            }
            hidePrevButton={
              Math.ceil(filteredCollections.length / PER_PAGE) <= 1
                ? true
                : false
            }
          />
        </div>
      </div>
      <div
        id="bountyCollections"
        className="flex flex-col h-screen space-y-16 self-center w-full overflow-y-auto no-scrollbar"
        style={{ maxWidth: "984px" }}
      >
        {Object.keys(collections).length > 0 ? (
          <>
            {paginatedCollections.length > 0 ? (
              paginatedCollections.map((c) => (
                <Collection key={c.address} collection={c.address} />
              ))
            ) : (
              <div className="flex flex-col h-screen flex-grow justify-center">
                <div className="flex flex-col w-full text-center sm:py-36 lg:py-64 text-dark-white-64">
                  <p className="body1">End of search results.</p>
                  <p className="body1">Try broadening your query :)</p>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex flex-col h-screen flex-grow justify-center">
            <div className="flex flex-row justify-center pb-32">
              <h2 className="text-white">
                Sorry! None of your NFTs currently qualify.
              </h2>
            </div>
            <div className="flex justify-center pb-32">
              <a
                href="https://docs.google.com/spreadsheets/d/1V9ZFSONEML4hqf1qySDiETZq6ZQmIEeZLpwdA9Ssm7A/edit?usp=sharing"
                target="_blank"
              >
                <Button>View qualifying NFTs</Button>
              </a>
            </div>
          </div>
        )}
      </div>

      <NFTScrollContainer
        title={`${selected.length} NFT${selected.length > 1 ? "s" : ""}`}
        subtitle="Trade NFTs for preset album token rates"
        inventoryNFTs={selected}
        onNftRemoved={handleRemoveNft}
        onClearAll={handleClearAll}
      />
    </div>
  );
};

export default SwapNFT;
