import ApproveCard from "components/common/nft/collection/approveCard";
import { CONTRACT_ADDRS } from "constants/contractAddrs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import { setAllContractsApproved } from "state/bounty/actions";

const Approval = () => {
  const dispatch = useDispatch();
  const [approvals, setApprovals] = useState({});

  const collections = useSelector<AppState, AppState["bounty"]["selected"]>(
    (state) => state.bounty.selected
  );

  useEffect(() => {
    if (collections) {
      let collectionMap = {};
      collections.forEach((c) => {
        collectionMap[c.type.contract] = {
          name: c.type.name,
          numSelected: collectionMap[c.type.contract]?.numSelected
            ? collectionMap[c.type.contract].numSelected + 1
            : 1,
          isApproved: false,
        };
      });

      setApprovals(collectionMap);
    }
  }, [collections]);

  const handleUpdateApprovals = (approved: boolean, collection: string) => {
    setApprovals({
      ...approvals,
      [collection]: {
        ...approvals[collection],
        isApproved: approved,
      },
    });
  };

  //Check each collection is approved
  const checkAllApproved = (): boolean => {
    return Object.keys(approvals).every((collection) => {
      return approvals[collection].isApproved;
    });
  };

  useEffect(() => {
    if (checkAllApproved()) {
      dispatch(setAllContractsApproved(true));
    } else {
      dispatch(setAllContractsApproved(false));
    }
  }, [approvals]);

  return (
    <div className="flex flex-col h-full">
      <div className="overflow-y-auto flew flow-col">
        <p className="body1 text-dark-white-64 text-center my-32">
          Approval is required once per NFT contract
        </p>
        <div className="flex flex-col md:justify-center items-center w-full">
          <div
            className={`grid grid-cols grid-cols-1 md:grid-cols-2 gap-24 mx-auto flex-grow pb-16 max-h-full lg:grid-cols-3`}
          >
            {Object.keys(approvals).map((collection) => (
              <ApproveCard
                key={collection}
                collection={collection}
                collectionName={approvals[collection].name}
                operator={CONTRACT_ADDRS.BOUNTY_BOARD}
                subtitle={`${approvals[collection].numSelected} NFT${
                  approvals[collection].numSelected > 1 ? "s" : ""
                }`}
                onApprove={(approved, collection) =>
                  handleUpdateApprovals(approved, collection)
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approval;
